<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="dashboard.inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="dashboard.inputs"
        :reactiveVar="reactiveVar"
        :numberOfCols="3"
        @submit-form="getRegionSummaryListActions(reactiveVar)"
      >
      </base-form>
    </base-expansion-panel>
    <div class="my-2"></div>
    <v-simple-table class="mx-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 260px">工程</th>
            <th class="text-right" v-for="region in regions" :key="region.id">
              {{ region.name }}
            </th>
            <th class="text-right">合計</th>
            <th class="text-center">
              <div class="d-flex flex-column justify-center">
                CSV出力
                <v-simple-checkbox
                  :value="checkAll"
                  @click="clickCheckAll"
                  color="primary"
                  style="margin: auto"
                ></v-simple-checkbox>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <Fragment v-for="item in dashboardItems" :key="item.key">
            <tr>
              <td>
                <div class="d-flex">
                  <span class="flex-grow-1">{{ item.label }}</span>
                  <v-btn
                    v-if="item.details.length !== 0"
                    fab
                    text
                    elevation="0"
                    x-small
                    color="primary"
                    @click="expand(item.key)"
                  >
                    <v-icon v-if="item.expand">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </td>
              <td class="text-right" v-for="region in regions" :key="region.id">
                {{ item.values.find((v) => v.region === region.id).value }}
              </td>
              <td class="text-right">{{ item.sum }}</td>
              <td class="text-center">
                <v-simple-checkbox
                  v-if="item.key !== '-'"
                  :value="item.checked"
                  @click="clickCheck({ key: item.key, checked: !!item.checked })"
                  color="primary"
                  style="margin: auto"
                ></v-simple-checkbox>
              </td>
            </tr>
            <tr v-show="item.expand" v-for="d in item.details" :key="d.key">
              <td class="text-right">{{ d.label }}</td>
              <td class="text-right" v-for="region in regions" :key="region.id">
                {{ d.values.find((v) => v.region === region.id).value }}
              </td>
              <td class="text-right">{{ d.sum }}</td>
              <td class="text-center">
                <v-simple-checkbox
                  :value="d.checked"
                  @click="clickCheck({ key: d.key, checked: !!d.checked })"
                  color="primary"
                  style="margin: auto"
                ></v-simple-checkbox>
              </td>
            </tr>
          </Fragment>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions class="justify-end px-3">
      <v-btn :disabled="csvFlag" @click="exportDashboardCsvFileActions(reactiveVar)" color="primary">{{
        label.BTN_CSV_OUTPUT
      }}</v-btn>
      <v-btn color="success" @click="showCslMatchingModal = true">{{ label.CSL_MATCHING_LOG }}</v-btn>
    </v-card-actions>

    <v-dialog v-model="showCslMatchingModal" max-width="700px" style="background: red">
      <v-card color="white">
        <v-col>
          <v-row>
            <v-card-title class="primary text-subtitile-2 white--text py-1" style="width: 700px">
              <v-icon color="white" large>mdi-download</v-icon>
              <span>{{ label.CSL_MATCHING_LOG }}</span>
            </v-card-title>
          </v-row>
          <v-row class="pt-4">
            <v-col>
              <Calendar
                :input="{ label: label.CSL_MATCHING_DATE_FROM, range: false }"
                @date-range="setDate"
                reactiveVarKey="cslMatchingFromDate"
              />
            </v-col>
            <v-col>
              <v-text-field
                max="23"
                min="0"
                type="number"
                outlined
                dense
                clearable
                :label="label.HOUR"
                placeholder="HH"
                v-model="csvConditions.cslMatchingFromHour"
              ></v-text-field>
            </v-col>
            <div class="mt-5">:</div>
            <v-col>
              <v-text-field
                type="number"
                max="59"
                min="0"
                outlined
                dense
                clearable
                :label="label.MINUTE"
                placeholder="MM"
                v-model="csvConditions.cslMatchingFromMinutes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Calendar
                :input="{ label: label.CSL_MATCHING_DATE_TO, range: false }"
                @date-range="setDate"
                reactiveVarKey="cslMatchingToDate"
              />
            </v-col>
            <v-col>
              <v-text-field
                max="23"
                min="0"
                type="number"
                outlined
                dense
                clearable
                :label="label.HOUR"
                placeholder="HH"
                v-model="csvConditions.cslMatchingToHour"
              ></v-text-field>
            </v-col>
            <div class="mt-5">:</div>
            <v-col>
              <v-text-field
                type="number"
                max="59"
                min="0"
                outlined
                dense
                clearable
                :label="label.MINUTE"
                placeholder="MM"
                v-model="csvConditions.cslMatchingToMinutes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Calendar
                :input="{ label: label.SHUKKA_DATE, range: true }"
                @date-range="setDate"
                reactiveVarKey="shippingDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                clearable
                :label="label.SHUKKATOSHI_NO"
                prepend-icon="mdi-file-document"
                v-model="csvConditions.shippingSeqNo"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                clearable
                :label="label.MEMBER_NAME"
                prepend-icon="mdi-account-tie"
                v-model="csvConditions.memberName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                clearable
                :label="label.ANKEN_NAIYO"
                prepend-icon="mdi-file-document"
                v-model="csvConditions.matterNo"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                clearable
                :label="label.TRACKING_NO"
                prepend-icon="mdi-file-document"
                v-model="csvConditions.trackingNo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-card-actions class="justify-end pb-5">
          <v-btn
            color="primary"
            :disabled="
              ((isDataEmpty(this.csvConditions.cslMatchingFromDate) ||
                isDataEmpty(this.csvConditions.cslMatchingFromHour) ||
                isDataEmpty(this.csvConditions.cslMatchingFromMinutes)) &&
                !(
                  isDataEmpty(this.csvConditions.cslMatchingFromHour) &&
                  isDataEmpty(this.csvConditions.cslMatchingFromMinutes)
                )) ||
              ((isDataEmpty(this.csvConditions.cslMatchingToDate) ||
                isDataEmpty(this.csvConditions.cslMatchingToHour) ||
                isDataEmpty(this.csvConditions.cslMatchingToMinutes)) &&
                !(
                  isDataEmpty(this.csvConditions.cslMatchingToHour) &&
                  isDataEmpty(this.csvConditions.cslMatchingToMinutes)
                ))
            "
            outlined
            @click="downloadCslMatchingLog"
            >{{ label.BTN_CSV_OUTPUT }}</v-btn
          >
          <v-btn color="warning" text @click="showCslMatchingModal = false">{{ label.BTN_CANCEL }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Calendar from '@/components/ui/Calendar.vue';
import { label } from '@/consts.js';
import { Fragment } from 'vue-fragment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Dashboard',
  components: { Fragment, Calendar },
  data() {
    return {
      tableStyle: {
        // border: "thin solid rgba(255, 255, 255, 0.9)",
        // borderRadius: "4px",
      },
      tableHeaderStyle: {
        backgroundColor: '#E0E0E0',
        // borderRadius: "4px",
      },
      showCslMatchingModal: false,
      csvConditions: {
        shippingSeqNo: '',
        cslMatchingFromDate: '',
        cslMatchingFromHour: '',
        cslMatchingFromMinutes: '',
        cslMatchingToDate: '',
        cslMatchingToHour: '',
        cslMatchingToMinutes: '',
        shippingDate: [],
        memberName: '',
        matterNo: '',
        trackingNo: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      dashboard: 'defined/dashboard',
      kouteiName: 'defined/pathNames',
      label: 'defined/labels',
      dashboardItems: 'dashboard/dashboardItems',
      csvFlag: 'dashboard/csvFlag',
      regions: 'dashboard/regions',
      checkAll: 'dashboard/checkAll',
      reactiveVar: 'dashboard/reactiveVar',
      messages: 'defined/messages',
    }),
    label() {
      return label;
    },
  },
  async created() {
    await this.getRegionSummaryListActions(this.reactiveVar);
  },
  methods: {
    ...mapActions({
      req: 'api/callAPI',
      getRegionSummaryListActions: 'dashboard/getRegionSummaryListActions',
      exportDashboardCsvFileActions: 'dashboard/exportDashboardCsvFileActions',
      apiDownloadCslMatchingLog: 'api/downloadCslMatchingLog',
    }),
    ...mapMutations({
      clickCheckAll: 'dashboard/clickCheckAll',
      clickCheck: 'dashboard/clickCheck',
      expand: 'dashboard/expand',
    }),
    setDate(date, key) {
      this.csvConditions[key] = date;
    },
    downloadCslMatchingLog() {
      if (this.csvConditions.cslMatchingFromHour?.length === 1) {
        this.csvConditions.cslMatchingFromHour = '0' + this.csvConditions.cslMatchingFromHour;
      }
      if (this.csvConditions.cslMatchingFromMinutes?.length === 1) {
        this.csvConditions.cslMatchingFromMinutes = '0' + this.csvConditions.cslMatchingFromMinutes;
      }
      if (this.csvConditions.cslMatchingToHour?.length === 1) {
        this.csvConditions.cslMatchingToHour = '0' + this.csvConditions.cslMatchingToHour;
      }
      if (this.csvConditions.cslMatchingToMinutes?.length === 1) {
        this.csvConditions.cslMatchingToMinutes = '0' + this.csvConditions.cslMatchingToMinutes;
      }

      let cslMatchingFromDatetime = this.csvConditions.cslMatchingFromDate ?? '';
      if (cslMatchingFromDatetime) {
        const hh = this.csvConditions.cslMatchingFromHour.padStart(2, '0');
        const mm = this.csvConditions.cslMatchingFromMinutes.padStart(2, '0');
        cslMatchingFromDatetime = `${cslMatchingFromDatetime} ${hh}:${mm}`;
      }

      let cslMatchingToDatetime = this.csvConditions.cslMatchingToDate ?? '';
      if (cslMatchingToDatetime) {
        const hh = this.csvConditions.cslMatchingToHour ? this.csvConditions.cslMatchingToHour.padStart(2, '0') : '23';
        const mm = this.csvConditions.cslMatchingToMinutes
          ? this.csvConditions.cslMatchingToMinutes.padStart(2, '0')
          : '59';
        cslMatchingToDatetime = `${cslMatchingToDatetime} ${hh}:${mm}`;
      }

      this.csvConditions.shippingDate[0] = this.csvConditions.shippingDate[0]?.replace(/-/g, '/') ?? '';
      this.csvConditions.shippingDate[1] = this.csvConditions.shippingDate[1]?.replace(/-/g, '/') ?? '';

      const payload = {
        cslMatchingFromDatetime,
        cslMatchingToDatetime,
        shippingFromDate: this.csvConditions.shippingDate[0],
        shippingToDate: this.csvConditions.shippingDate[1],
        shippingSeqNo: this.csvConditions.shippingSeqNo ?? '',
        memberName: this.csvConditions.memberName ?? '',
        matterNo: this.csvConditions.matterNo ?? '',
        trackingNo: this.csvConditions.trackingNo ?? '',
      };
      this.apiDownloadCslMatchingLog(payload);
    },
    isDataEmpty(data) {
      return data === null || data === undefined || data === '';
    },
  },
};
</script>
<style scoped>
.theme--light.v-data-table :deep() table {
  border: thin solid rgba(0, 0, 0, 0.9);
  border-radius: 4px;
}
.theme--dark.v-data-table :deep() table {
  border: thin solid rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}
.theme--light.v-data-table :deep() thead {
  background-color: #e0e0e0;
}
.theme--light.v-data-table :deep() thead.v-data-table-header {
  background: rgb(224, 224, 224);
  white-space: pre;
}
.theme--dark.v-data-table :deep() thead.v-data-table-header {
  background: rgba(0, 43, 91, 0.671);
  white-space: pre;
}

.theme--light.v-data-table :deep() th:last-child,
.theme--light.v-data-table :deep() td:last-child,
.theme--dark.v-data-table :deep() th:last-child,
.theme--dark.v-data-table :deep() td:last-child {
  border-right: none !important;
}

.theme--light.v-data-table :deep() .v-data-footer,
.theme--dark.v-data-table :deep() .v-data-footer {
  border-top: none !important;
}

.theme--light.v-data-table :deep() th,
.theme--light.v-data-table :deep() td {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-data-table :deep() th,
.theme--dark.v-data-table :deep() td {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.theme--light.v-data-table :deep() span.v-data-table-header__icon,
.theme--dark.v-data-table :deep() span.v-data-table-header__icon {
  opacity: 1;
}

.theme--light.v-data-table :deep() tbody tr#stop_hover:hover,
.theme--light.v-data-table :deep() tbody tr.text-decoration-line-through:hover {
  background: transparent !important;
}
.theme--light.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #8acbffb5 !important;
}

.theme--dark.v-data-table :deep() tbody tr#stop_hover:hover,
.theme--dark.v-data-table :deep() tbody tr.text-decoration-line-through:hover {
  background: transparent !important;
}
.theme--dark.v-data-table
  :deep()
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(#stop_hover):not(
    .text-decoration-line-through
  ):not(.not-change-hover-color) {
  background: #045ba2d6 !important;
}

.theme--light.v-data-table :deep() tr.v-data-table__selected:not(.disabled-row-color) {
  background: #8acbffb5 !important;
}
.theme--dark.v-data-table :deep() tr.v-data-table__selected:not(.disabled-row-color) {
  background: #045ba2d6 !important;
}

.theme--light.v-data-table :deep() tr.highlight-green {
  background-color: #d3ffd393 !important;
}
.theme--dark.v-data-table :deep() tr.highlight-green {
  background-color: rgba(0, 255, 51, 0.084) !important;
}
</style>
